import mixpanel from "mixpanel-browser";

export const AnalyticsInit = () => {
  mixpanel.init(process.env.REACT_APP_MIXPANEL_PROJECT_TOKEN!, {
    debug: process.env.NODE_ENV === "development",
    persistence: "localStorage",
    ignore_dnt: true,
  });
};

export default mixpanel;
